<template>
    <div v-if="isStoreInitialized">
        <!-- title -->
        <div class="container-fluid bg-area-of-expertise-header px-0 py-5 position-relative">
            <div class="row position-relative m-0">
                <div class="col-12 py-5 px-0">
                    <div class="container py-0">
                        <div class="row">
                            <div class="col-12 text-white text-center py-5">
                                <h1 class="d-lg-block d-none font-size-area-of-expertise-header mp-rbk mp-font-weight-medium my-5 py-5">Glossar</h1>
                                <h1 class="d-lg-none d-block font-size-area-of-expertise-header-mobile mp-rbk mp-font-weight-medium my-0 py-0">Glossar</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /title -->

        <div class="container-fluid bg-lightblue py-5 d-none d-md-block">
            <!-- category -->
            <template v-for="c in categories">
                <div v-if="c.areas.length > 0" class="row mb-3" :key="`${keyPrefix}_${c.detail.id}`">
                    <div class="col-12">
                        <div class="container py-1 bg-white form-rounded-left form-rounded-right area-of-expertise-category">
                            <div class="row mt-3 mb-4">
                                <!-- category -->
                                <div class="col-12 text-start">
                                    <span
                                        v-if="c._hasExperts"
                                        class="mp-rbk d-block aoe-title"
                                        @click="goTo(`/fachgebiete/${c.detail.title}/${c.detail.id}`)">{{ c.detail.title }}
                                    </span>
                                    <span v-else class="mp-rbk d-block aoe-title">{{ c.detail.title }}</span>
                                </div>
                                <div class="col-12 text-start ps-5 pe-5 mt-2 mb-3">
                                    <span style="display: block; text-align: justify;">{{ c.detail.desc }}</span>
                                </div>
                                <!-- areas -->
                                <div class="col-12">
                                    <div class="row mt-3" v-for="a in c.areas" :key="`${keyPrefix}_${c.detail.id}_${a.id}_img`">
                                        <div class="col-12 text-start ps-5" style="font-weight: bold">
                                            <span v-if="a._hasExperts" @click="goTo(`/fachgebiete/${c.detail.title}/${c.detail.id}/${a.title}/${a.id}`)">{{a.title}}</span>
                                            <span v-else>{{a.title}}</span>
                                        </div>

                                        <div class="col-12 text-start ps-5 pe-5">
                                            <span style="display: block; text-align: justify;">{{a.desc}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <!-- mobile -->
        <div class="container-fluid bg-lightblue py-5 d-md-none d-block">
            <!-- category -->
            <template v-for="c in categories">
                <div v-if="c.areas.length > 0" class="row mb-3" :key="`${keyPrefix}_${c.detail.id}`">
                    <div class="col-12">
                        <div class="container py-1 bg-white form-rounded-left form-rounded-right area-of-expertise-category">
                            <div class="row mt-3 mb-4">
                                <!-- category -->
                                <div class="col-12 text-start">
                                    <span v-if="c._hasExperts" class="mp-rbk d-block aoe-title" style="cursor:pointer;" @click="goTo(`/expert/${c.detail.id}`)">{{ c.detail.title }}</span>
                                    <span v-else class="mp-rbk d-block aoe-title">{{ c.detail.title }}</span>
                                </div>
                                <div class="col-12 text-start ps-5 pe-5 mt-2 mb-3">
                                    <span style="display: block; text-align: justify;">{{ c.detail.desc }}</span>
                                </div>
                                <!-- areas -->
                                <div class="col-12">
                                    <div class="row mt-3" v-for="a in c.areas" :key="`${keyPrefix}_${c.detail.id}_${a.id}_img`">
                                        <div class="col-12 text-start ps-5" style="font-weight: bold; cursor:pointer;" @click="areaToggleVisibility(a.id)">
                                            <span>{{a.title}}</span>
                                        </div>
                                        <div v-if="areaIsVisible(a.id)" class="col-12 text-start ps-5 pe-5">
                                            <span style="display: block; text-align: justify;">{{a.desc}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <TagCloud class="d-none d-md-block"/>
    </div>
</template>



<script>
    import {mixinBasics} from "../../mixins/basics";
    import TagCloud from "../../components/TagCloud";

    export default {
        name: "Glossary",

        metaInfo: {
            title: 'Glossar',
            meta: [
                { name: 'Glossar' },
                { title: 'Glossar' }
            ]
        },

        components: {
            TagCloud
        },

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'glossary',
                areaVisibility: [],
            };
        },

        computed: {
            categories() {
                return this.getCategories();
            }
        },

        methods: {
            getAreas(categoryId) {
                return this._categories.find(c => c.id === categoryId).areas
                    //.filter(a => this._experts.filter(e => e.areas.findIndex(ea => ea.id === a.id && ea.verified) >= 0).length > 0)
                    .sort((a,b) => {
                        const nameA = a.title.toUpperCase();
                        const nameB = b.title.toUpperCase();
                        return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
                    })
                    .map(a => {
                        this.areaVisibility.push({id: a.id, visible: false});
                        return Object.assign(a, {
                            _visible: false,
                            _hasExperts: this._experts.findIndex( e => e.areas.findIndex(ea => ea.id === a.id && ea.verified) >= 0) >= 0
                        });
                    });
            },

            getCategories() {
                return this._categories
                    /*
                    .filter(c =>
                    // return only categories where experts r available
                    this._experts.filter(e => e.categories.find(ec => ec.id === c.id)).length > 0
                    )
                    */
                    .sort((a,b) => {
                        const nameA = a.title.toUpperCase();
                        const nameB = b.title.toUpperCase();
                        return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
                    })
                    .map(c => {
                        return {
                            'detail': c,
                            '_hasExperts': this._experts.findIndex(e => e.categories.findIndex(ec => ec.id === c.id) >= 0) >= 0,
                            'areas': this.getAreas(c.id)
                        }
                    });
            },

            areaToggleVisibility(areaId) {
                let idx = this.areaVisibility.findIndex(a => a.id === areaId);
                this.areaVisibility[idx].visible = !this.areaVisibility[idx].visible;
            },

            areaIsVisible(areaId) {
                return this.areaVisibility.find(a => a.id === areaId).visible;
            },

            goTo(url) {
                console.log(url);
                //this.$router.push(url);
            }
        },

        mounted() {
            window.scrollTo(0,0);
        }

    }
</script>



<style scoped>

</style>